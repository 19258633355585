var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-6" }, [
    _c("div", { staticClass: "descriptions mb-7" }, [
      _vm._v(" " + _vm._s(_vm.$t("interface.import.desc")) + " "),
    ]),
    _c(
      "div",
      { staticClass: "d-block d-sm-flex align-start text-center" },
      [
        _c("v-file-input", {
          attrs: {
            color: "greenPrimary",
            label: "Upload file...",
            "truncate-length": "15",
            filled: "",
            accept: "application/JSON",
            height: "60",
          },
          on: { change: _vm.handleChange },
        }),
        _c("mew-button", {
          staticClass: "ml-3",
          attrs: {
            title: "Confirm & Import",
            "btn-size": "xlarge",
            disabled: _vm.file === "",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.clickImport.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }